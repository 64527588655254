<script lang="ts" setup>
import { useUniversalPixel } from "~/composables/universalPixel";

useUniversalPixel().init();
</script>

<template>
    <div>
        <HeaderContent ref="header" />
        <main ref="mainContent" class="relative min-h-screen pt-32 print:pt-10">
            <slot />
        </main>
        <FooterContent />
    </div>
</template>

<style scoped></style>
